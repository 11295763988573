import {
	ValidateNested,
	IsNotEmpty,
	ArrayNotEmpty,
	ArrayMinSize,
	IsString,
	IsDateString,
	IsNumber,
	IsUrl,
	ValidateIf,
	IsBoolean,
	IsOptional,
	IsObject,
	IsArray,
	Min,
	IsEnum,
	IsUUID
} from 'class-validator';

import { Type } from 'class-transformer';
import { CartItemType, ProjectCartItem } from '../../cart/project-cart-item.entity';
import { CreativeUnitDto } from '../../creative-unit-package/models/creative-unit/creative-unit.dto';
import { CreativeUnitPackage, ExportType } from '../../creative-unit-package/creative-unit-package.entity';
import { PrintConfigDto } from '../../creative-unit-package/models/print.dto';

export enum AudioLayer {
	VO = 'vo',
	Music = 'music',
	SFX = 'sfx'
}

export enum CustomFontsLoadingMode {
	DEFAULT = 'default',
	LOCAL = 'local'
}

export enum TextLayerRenderingMode {
	DEFAULT = 'default',
	IMAGE = 'image'
}

export const BoxDefaultValue = { llx: '0', lly: '0', urx: '0', ury: '0' };

export enum AdPublisher {
	DEFAULT = 'default',
	GOOGLE = 'google',
	SIZMEK = 'sizmek',
	AGORA = 'agora',
	FLASHTALKING = 'flashtalking',
	INTERIA = 'interia',
	ONET = 'onet',
	TVN = 'tvn',
	WP = 'wp'
}

export enum FileUnit {
	KB = 'kB',
	MB = 'MB'
}

export enum ImageFormat {
	JPG = 'jpg',
	PNG = 'png'
}

export enum FilenameFormatType {
	PROJECT_NAME_DATE = 'project_name_date',
	CUSTOM = 'custom'

}

class Client {
	@IsNotEmpty()
	@IsString()
	id: string;

	@IsNotEmpty()
	@IsString()
	name: string;
}

export class FilenameConfig {
	@IsOptional()
	@IsString()
	formatType?: string;

	@IsOptional()
	@IsString()
	filename?: string;
}

class JobMeta {
	@IsNotEmpty()
	@IsDateString()
	date: string;

	@IsOptional()
	@IsString()
	outputFolder?: string;

	@IsOptional()
	@IsString()
	outputName?: string;

	@IsOptional()
	@IsBoolean()
	automaticDuration?: boolean;

	@IsOptional()
	@ValidateNested()
	@Type(() => FilenameConfig)
	public exportFilename?: FilenameConfig;
}

class ClipData {
	@IsNotEmpty()
	@IsString()
	@IsUrl()
	path: string;

	@IsOptional()
	@IsString()
	@IsUrl()
	overlay?: string;

	@IsOptional()
	@IsString()
	overlayData?: string;

	@IsOptional()
	@IsBoolean()
	useGlobalOverlay?: boolean;

	@IsOptional()
	@IsNumber()
	seek?: number;

	@IsOptional()
	@IsNumber()
	duration?: number;

	@IsOptional()
	@IsNumber()
	horizontalOffset?: number;

	@IsOptional()
	@IsNumber()
	verticalOffset?: number;

	@IsOptional()
	@IsNumber()
	overlayOffset?: number;

	@IsOptional()
	@IsNumber()
	overlayDuration?: number;

	@ValidateIf(o => o.horizontalOffset != null || o.verticalOffset != null)
	@IsString()
	@IsUrl()
	@IsOptional()
	underlay?: string;

	@IsOptional()
	@IsNumber()
	@Min(1)
	scaleX?: number;

	@IsOptional()
	@IsNumber()
	audioImusic?: number;

	@IsOptional()
	@IsNumber()
	audioIvo?: number;

	@IsOptional()
	@IsNumber()
	audioIvideo?: number;

	@IsOptional()
	audioTransitionLayersEnabled?: AudioLayer[];

	@IsOptional()
	audioTransitionIn?: { st: number; duration: number };

	@IsOptional()
	audioTransitionOut?: { st: number };
}

export class AudioClip {
	@IsNotEmpty()
	@IsString()
	@IsUrl()
	path: string;

	@IsOptional()
	@IsNumber()
	seek?: number;

	@IsOptional()
	@IsNumber()
	duration?: number;

	@IsOptional()
	@IsNumber()
	offset?: number;
}

export class OutputConfig {
	@IsOptional()
	@IsNumber()
	resolutionWidth?: number;

	@IsOptional()
	@IsNumber()
	resolutionHeight?: number;

	@IsOptional()
	@IsNumber()
	frameRate?: number;

	@IsOptional()
	@IsNumber()
	durationMs?: number;

	@IsOptional()
	@IsEnum(CustomFontsLoadingMode)
	customFontLoading?: CustomFontsLoadingMode;

	@IsOptional()
	@IsEnum(TextLayerRenderingMode)
	textLayerRenderingMode?: TextLayerRenderingMode;

	@IsOptional()
	@IsEnum(AdPublisher, { each: true })
	adPublishers?: AdPublisher[];

	@IsOptional()
	@IsEnum(AdPublisher)
	adPublisher?: AdPublisher;

	@IsOptional()
	targetURL?: string;

	@IsOptional()
	@IsNumber()
	fileSize?: number;

	@IsOptional()
	@IsEnum(FileUnit)
	fileUnit?: FileUnit;

	@IsOptional()
	@IsEnum(ImageFormat)
	imageFormat?: ImageFormat;
}

export class Video {
	@IsOptional()
	@IsString()
	preset?: string;

	@IsOptional()
	@ValidateNested()
	output?: OutputConfig;

	@IsNumber()
	@IsNotEmpty()
	duration: number;

	@IsOptional()
	@IsString()
	audio?: string;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => AudioClip)
	audioClipData?: AudioClip[];

	@IsOptional()
	@IsString()
	@IsUrl()
	overlay?: string;

	@IsOptional()
	@IsString()
	overlayData?: string;

	@ArrayNotEmpty()
	@ArrayMinSize(1)
	@ValidateNested({ each: true })
	@Type(() => ClipData)
	clipData: ClipData[];
}

export class JobItem {
	@IsString()
	type: CartItemType;

	@IsUUID()
	cartItemId: string;

	@IsObject()
	@IsOptional()
	config: object;
}

export class JobDefDto {
	public downloadPage?: string;
	public callbackHost?: string;

	@ValidateNested()
	@Type(() => Client)
	public client: Client;

	@IsOptional()
	@IsString()
	public tag?: string;

	@IsOptional()
	@IsBoolean()
	public autoApprove?: boolean;

	@ValidateNested()
	@Type(() => JobMeta)
	public meta: JobMeta;

	// legacy
	@ValidateIf(v => !v.videos && !v.items)
	@ValidateNested()
	@Type(() => Video)
	public video?: Video;

	// legacy
	@ValidateIf(v => !v.video && !v.items)
	@ArrayNotEmpty()
	@ArrayMinSize(1)
	@ValidateNested({ each: true })
	@Type(() => Video)
	public videos?: Video[];

	public userLocale?: string;
}

export class AdBuilderData extends CreativeUnitDto {
	@IsOptional()
	package?: CreativeUnitPackage;

	@IsOptional()
	printConfig?: PrintConfigDto;
}

export class AdBuilder {
	@IsOptional()
	@ValidateNested()
	@Type(() => OutputConfig)
	output?: OutputConfig;

	@IsOptional()
	@IsEnum(ExportType, { each: true })
	formats?: ExportType[];

	@ValidateNested()
	@Type(() => AdBuilderData)
	builderData: AdBuilderData;
}

export class HtmlBuilderData {
	@IsOptional()
	@IsString()
	// Should be JSON stringified
	htmlTemplate?: string;

	@IsOptional()
	@IsString()
	// Should be JSON stringified
	textTemplate?: string;
}

export enum HtmlFormat {
	HTML = 'html',
	Text = 'text',
	Email = 'email'
}

// TODO
export class EmailOptions {
	@IsOptional()
	@IsString()
	subject?: string;
	
	@IsOptional()
	@IsString()
	from?: string;

	@IsOptional()
	@IsString()
	to?: string;
}

export class HTMLBuilder {
	@IsOptional()
	@IsEnum(HtmlFormat, { each: true })
	formats?: HtmlFormat[];

	@ValidateNested()
	@Type(() => HtmlBuilderData)
	builderData: HtmlBuilderData;

	@IsOptional()
	@IsBoolean()
	skipImageCache?: boolean;

	@IsOptional()
	@ValidateNested()
	@Type(() => EmailOptions)
	emailOptions?: EmailOptions;
}

export class CartJobDto {
	public downloadPage?: string;
	public callbackHost?: string;

	@ValidateNested()
	@Type(() => Client)
	public client: Client;

	@IsOptional()
	@IsString()
	public userId?: string;

	@IsOptional()
	@IsString()
	public tag?: string;

	@IsOptional()
	@IsBoolean()
	public autoApprove?: boolean;

	@ValidateNested()
	@Type(() => JobMeta)
	public meta: JobMeta;

	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => JobItem)
	public items: JobItem[];

	public userLocale?: string;
}

export class TaskDto {
	public downloadPage?: string;
	public callbackHost?: string;

	@ValidateNested()
	@Type(() => Client)
	public client: Client;

	@IsOptional()
	@IsString()
	public tag?: string;

	@IsOptional()
	@IsString()
	public cartItemId?: string;

	@IsOptional()
	@IsBoolean()
	public autoApprove?: boolean;

	@ValidateNested()
	@Type(() => JobMeta)
	public meta: JobMeta;

	@IsOptional()
	@ValidateNested()
	@Type(() => Video)
	public video?: Video;

	@IsOptional()
	@ValidateNested()
	@Type(() => AdBuilder)
	public adBuilder?: AdBuilder;

	@IsOptional()
	@ValidateNested()
	@Type(() => AdBuilder)
	public adOptimizer?: AdBuilder;

	@IsOptional()
	@ValidateNested()
	@Type(() => AdBuilder)
	public pdfBuilder?: AdBuilder;

	@IsOptional()
	@ValidateNested()
	@Type(() => HTMLBuilder)
	public htmlBuilder?: HTMLBuilder;

	public userLocale?: string;
}
