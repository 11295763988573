/**
 * Merge Tags Utils
 * A set of utilities for working with merge tags in strings.
 *
 * Example usage:
  	const template = `
  		Hello {{user.name}}, you have {{fn(return state.variables.user?.subscriptions?.length;)}} subscription that
		is {{user.subscriptions->find:plan:eq:premium->details.value}}.
	";

	const variables = {
		user: {
		name: "Alice",
		subscriptions: [
			{ plan: "free", details: "Basic access" },
			{ plan: "premium", details: "Full access with premium features" }
		],
		emails: [{ address: "alice@example.com" }]
		}
	};
 */

/**
 * A map of function names to actual callable functions.
 *
 * Example usage:
 * const helperFns: MergeTagHelperFunctions = {
 *   greet: (name: string) => `Hello, ${name}!`,
 *   add: (a: number, b: number) => a + b,
 * };
 */
interface MergeTagHelperFunctions {
	[fnName: string]: (...args: any[]) => any;
}

export class MergeTags {

	/**
	 * Applies merge tags to a template string using the provided variables.
	 *
	 * @param {string} templateString - The template string to apply merge tags to.
	 * @param {object} variables - The variables to use for replacing merge tags.
	 * @param {MergeTagHelperFunctions} helperFns - (Optional) A map of helper functions to make available inside fn().
	 * @returns {string} The template string with merge tags replaced by their corresponding values.
	 */
	public static applyMergeTagsToString(
		templateString: string,
		variables: any,
		helperFns: MergeTagHelperFunctions = {
			add(a: number, b: number) {
				return a + b;
			},
			greet(name: string) {
				// We can also call `this.add(10,20)` if we want,
				// but only if we rebind "this" (explained further below).
				// For now, let's keep it simple and not rely on "this":
				return `Hello, ${name}! Let's add 1+2 => ${this.add(1,2)}`;
			}
		}
	): string {

		// console.log("templateString final:", templateString);

		// Same regex / replacement logic, but we pass helperFns down:
		return templateString?.replace(
		/\{\{\s*(?:fn\s*\(\s*([\s\S]*?)\s*\)|([\w\s\/\-\>\:\._]+?)(?:\s*\|\|\s*'(.*?)')?)\s*\}\}/g,
		(match, fn, path, defaultValue) => {
			let value;

			if (fn) {
				Object.keys(helperFns).forEach(fnName => {
					helperFns[fnName] = helperFns[fnName].bind(helperFns);
				});

				// Use the updated evaluateExpression that accepts helperFns
				return this.evaluateExpression(fn, variables, helperFns);
			} else {
				value = this.getValueByPath(variables, path);
				if (value === undefined) {
					value = '';
				}
				return value !== undefined && value !== null && value !== '' ? value : defaultValue || '';
			}
		}
		);
	}

	/**
	 * Evaluate the fn(...) body with both `state` and the `helperFns`.
	 * We'll pass in each helper function as a separate parameter to new Function
	 * so the user can do:  {{ fn(return greet('Alice')) }}
	 */
	private static evaluateExpression(
	expression: string,
	state: any,
	helperFns: MergeTagHelperFunctions
	): any {
		// We want to create a dynamic function signature like:
		//   (state, add, greet, ...) => { <expression> }
		// so the user can call `greet(...)` as a top-level identifier.

		const fnNames = Object.keys(helperFns);          // e.g. ['add', 'greet']
		const fnValues = Object.values(helperFns);       // e.g. [ [Function], [Function] ]

		// Our param names are always 'state', then each function name
		const paramNames = ['state', ...fnNames];
		// Our param values match that order
		const paramValues = [state, ...fnValues];

		// Unescape the expression fully before we run it.
		// console.log('Evaluating expression:', expression);
		expression = expression
			// turn literal \n into real newline
			.replace(/\\n/g, '\n')
			// turn literal \r into real carriage return
			.replace(/\\r/g, '\r')
			// turn literal \t into tab
			.replace(/\\t/g, '\t')
			// handle escaped quotes
			.replace(/\\"/g, '"')
			.replace(/\\'/g, "'")
			// finally, collapse remaining double backslashes (\\ => \)
			.replace(/\\\\/g, '\\');

		// Create the dynamic function
		const dynamicFunction = new Function(...paramNames, expression);

		// Now call the dynamic function with the actual arguments
		return dynamicFunction(...paramValues);
	}



	/**
	 * Retrieves the value from an object using a given path.
	 * The path can include dot notation for nested properties and '->' for array find operations.
	 * If the value is not found, undefined is returned.
	 *
	 * @param variables - The object to search for the value.
	 * @param path - The path to the desired value.
	 * @returns The value found at the specified path, or undefined if not found.
	 */
	private static getValueByPath(variables, path) {
		// Initialize the current object to the passed variables
		let current = variables;

		// Split the path on '->', which may include a 'find' operation or be a direct path
		let pathSegments = path.split('->');

		// Iterate over the segments to process each one
		for (let i = 0; i < pathSegments.length; i++) {
			let segment = pathSegments[i];
			// console.log('Segment:', segment, segment.startsWith('find'), i, pathSegments);

			// Handle the find operation
			if (segment.startsWith('find')) {
				//&& i < pathSegments.length - 1
				// console.log('Find operation:', segment);
				// Remove find: from the segment
				let searchQuery = segment.replace('find:', '');
				current = this.findInArray(current, searchQuery);

				// console.log('Found:', current, searchQuery);

				if (current === undefined) {
					// If nothing is found, return undefined immediately
					return undefined;
				}
			} else {
				// Split the segment into subparts in case of dot notation
				let keys = segment.split('.');
				for (let key of keys) {
					if (current?.[key] === undefined) {
						return undefined; // If the key is not found, return undefined
					}
					current = current[key];
				}
			}
		}
		return current;
	}

	/**
	 * Finds an item in an array based on a search query.
	 * @param array - The array to search in.
	 * @param searchQuery - The search query in the format "key:condition:value".
	 * @returns The found item or undefined if not found.
	 * @throws Error if the search condition is unsupported.
	 */
	private static findInArray(array, searchQuery) {
		// Split the search query into its components: key, condition, and value
		let [key, condition, searchValue] = searchQuery.split(':');
		// Based on the condition, perform the appropriate search
		switch (condition) {
			case 'eq': // Equals condition
				return array.find(item => item[key] === searchValue);
			case 'neq': // Not equals condition
				return array.find(item => item[key] !== searchValue);
			default:
				throw new Error(`Unsupported search condition: ${condition}`);
		}
	}
}
