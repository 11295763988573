import { IsArray, IsBoolean, IsEnum, IsNumberString, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { LayerTextDto } from './layer-text.dto';
import { AnimationDto } from '../animation.dto';
import { VisibilityConditionDto } from '../visibility-condition.dto';
import { AssetDto } from '../../../asset/models/asset.dto';
import { LayerVideoDto } from './layer-video.dto';
import { TimingConfigDto } from '../timing.dto';
import { LayerAudioDto } from './layer-audio.dto';
import { LayerOptimizationsDto } from './layer-optimization.dto';
import { InteractivityDto } from '../interactivity.dto';
import { MergeTagDto } from "../merge-tag/merge-tag.dto";
import { LayerHtmlDto } from './layer-html.dto';

export enum LayerType {
	TEXT = 'text',
	HTML = 'html',
	IMAGE = 'image',
	VIDEO = 'video',
	AUDIO = 'audio',
	GROUP = 'group',
	MOCK = 'mock'
}

export class LayerCTAOptionsDto {
	@IsString()
	url: string;

	@IsString()
	@IsOptional()
	target?: string; // _blank | _self | _parent | _top | custom_frame_name
}

export class LayerDto {
	@IsString()
	id: string;

	@IsString()
	@IsOptional()
	label: string;

	@IsEnum(LayerType)
	type: LayerType;

	@IsString()
	@IsOptional()
	classes?: string;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerTextDto)
	text?: LayerTextDto;

	@IsOptional()
	html?: LayerHtmlDto;

	@IsOptional()
	image?: AssetDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerVideoDto)
	video?: LayerVideoDto;

	@IsOptional()
	audio?: AssetDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerCTAOptionsDto)
	cta?: LayerCTAOptionsDto;

	@IsOptional()
	@IsString()
	assetPath?: string; // Overrides the Asset properties above.

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerAudioDto)
	audioConfig?: LayerAudioDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => InteractivityDto)
	interactivity?: InteractivityDto;

	@IsOptional()
	@IsObject()
	style?: any;

	@IsOptional()
	@ValidateNested()
	@Type(() => AnimationDto)
	animation?: AnimationDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => TimingConfigDto)
	timing?: TimingConfigDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerOptimizationsDto)
	optimizations?: LayerOptimizationsDto;

	// Apply these optimizations on top of the original just for the preview window.
	@IsOptional()
	@ValidateNested()
	@Type(() => LayerOptimizationsDto)
	previewOptimizations?: LayerOptimizationsDto;

	@IsOptional()
	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => LayerDto)
	layers?: LayerDto[];

	@ValidateNested({ each: true })
	@IsArray()
	@IsOptional()
	@Type(() => VisibilityConditionDto)
	visibilityConditions?: VisibilityConditionDto[];

	@IsOptional()
	@IsBoolean()
	hideFromPreview?: boolean;

	@IsOptional()
	@IsBoolean()
	hideFromExport?: boolean;

	@IsOptional()
	@IsObject()
	mergeTags?: Record<string, MergeTagDto[]>;

	@IsOptional()
	@IsBoolean()
	hidden?: boolean;
}

export class LayerWithDomContextDto extends LayerDto {
	@IsObject()
	element: any; // HTMLElement

	@IsObject()
	creativeUnitId: string; // The associated creative unit ID for this layer.
}
