import { Type } from 'class-transformer';
import { IsBoolean, IsNumber, IsNumberString, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';

export class AnimationConfigDto {
	@IsOptional()
	@IsBoolean()
	autoPlay?: boolean;

	@IsOptional()
	@IsBoolean()
	loop?: boolean;

	@IsOptional()
	@IsBoolean()
	hideMarkersForUser?: boolean;

	@IsOptional()
	totalDuration?: string;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => TimelineMarkerDto)
	markers: TimelineMarkerDto[];
}

export class TimelineMarkerDto {
	@IsOptional()
	@IsString()
	id: string;

	@IsOptional()
	@IsString()
	label: string;

	@IsOptional()
	@IsString()
	slug: string;

	@IsOptional()
	time: string;
}

export class AnimationDto {
	@IsOptional()
	element?: any;

	@IsOptional()
	@IsObject()
	from?: Record<string, string | number>;

	@IsOptional()
	@IsObject()
	to?: Record<string, string | number>;

	@IsOptional()
	@IsString()
	method?: string;

	@IsOptional()
	position?: string | number;

	@IsOptional()
	duration?: string | number;

	@IsOptional()
	delay?: string | number;
}
