import { Type } from "class-transformer";
import { IsOptional, IsString, Validate, ValidateNested } from "class-validator";
import { AssetDto } from "../../asset/models/asset.dto";

export class CustomFontDto {
	@IsOptional()
	@IsString()
	id: string;

	@IsOptional()
	@IsString()
	name: string;

	@ValidateNested()
	@Type(() => AssetDto)
	src?: AssetDto;

	@IsOptional()
	@IsString()
	fontWeight: string;

	@IsOptional()
	@IsString()
	fontStyle: string;

	@IsOptional()
	@IsString()
	fontStretch: string;
}

export class CustomColorDto {
	@IsOptional()
	@IsString()
	label: string;

	@IsOptional()
	@IsString()
	value: string;
}

export class ThemeDto {
	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => CustomFontDto)
	customFonts: CustomFontDto[];

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => CustomColorDto)
	customColors: CustomColorDto[];
}
