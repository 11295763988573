import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdEditorGuard} from "./_core/guards/ad-editor.guard";

const routes: Routes = [
	{ path: '', loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsPageModule) },
	{ path: 'login', data: { animationKey: 'login' }, loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule) },
	{
		path: 'signup',
		data: { animationKey: 'signup' },
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'create-business',
		data: { animationKey: 'create-business' },
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'business-subscription/:id',
		data: { animationKey: 'business-subscription' },
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'business-trial/:id',
		data: { animationKey: 'business-trial' },
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'validate-subscription/:id',
		data: { animationKey: 'validate-subscription' },
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'setup-subscription/:id',
		data: { animationKey: 'setup-subscription' },
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
	},
	{ path: 'dashboard', loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsPageModule) },
	{ path: 'project', redirectTo: '', pathMatch: 'full' },
	{ path: 'project/:id', loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectPageModule) },
	{ path: 'review/:id', loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectPageModule) },
	{ path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule) },
	{ path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
	{ path: 'share', loadChildren: () => import('./pages/share/share.module').then(m => m.ShareModule) },
	{ path: 'onboard/external-payment-method', redirectTo: '/?onboard=external-payment-method', pathMatch: 'full' },
	{
		path: 'ad-editor',
		loadChildren: () => import('./pages/sandbox/ad-editor/ad-editor.module').then(m => m.AdEditorPageModule),
		canActivate: [AdEditorGuard]
	},
	{
		path: 'sandbox/ad-renderer',
		loadChildren: () => import('./pages/sandbox/ad-renderer/ad-renderer.module').then(m => m.AdRendererPageModule),
		canActivate: [AdEditorGuard]
	},
	{
		path: 'sandbox/:id/ad-renderer',
		loadChildren: () => import('./pages/sandbox/ad-renderer/ad-renderer.module').then(m => m.AdRendererPageModule),
		canActivate: [AdEditorGuard]
	},
	{
		path: 'sandbox/ad-editor',
		loadChildren: () => import('./pages/sandbox/ad-editor/ad-editor.module').then(m => m.AdEditorPageModule),
		canActivate: [AdEditorGuard]
	},
	{
		path: 'sandbox/ad-editor/:id',
		loadChildren: () => import('./pages/sandbox/ad-editor/ad-editor.module').then(m => m.AdEditorPageModule),
		canActivate: [AdEditorGuard]
	},
	{
		path: 'editor',
		loadChildren: () => import('./pages/sandbox/ad-editor/ad-editor.module').then(m => m.AdEditorPageModule),
		canActivate: [AdEditorGuard]
	},
	{
		path: 'editor/:id/:creativeUnitId',
		loadChildren: () => import('./pages/sandbox/ad-editor/ad-editor.module').then(m => m.AdEditorPageModule),
		canActivate: [AdEditorGuard]
	},
	{ path: '*', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
